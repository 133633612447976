$DIVIDER_COLOR: #d1d7dc;
$TEXT_COLOR: #616262;
$INPUT_BORDER_COLOR: #dfe3e9;
$PARAGRAPH_GREY: #9b9b9b;
$BACKGROUND_COLOR: #f9f9fb;
$RED_COLOR: #f36a6a;
$ACCENT_COLOR: #46b29e;
$FADED_ACCENT_COLOR: #dcedec;

$APP_SPACING_SIZE: 10px;

$LINK_TEXT_SIZE: 14px;
$LINK_TEXT_COLOR: $ACCENT_COLOR;
$LINK_LINE_HEIGHT: 21px;
$LINK_TEXT_WEIGHT: bold;

/*
Semantic UI Notes:
Header sizes:
@h1 : unit((28 / 14), rem);
@h2 : unit((24 / 14), rem);
@h3 : unit((18 / 14), rem);
@h4 : unit((15 / 14), rem);
@h5 : unit((14 / 14), rem);
*/


.header-1 {
	font-size:36px;
	color:$TEXT_COLOR;
	line-height: 41px;
	margin-bottom:10px;
}

.header-2 {
	font-size:26px;
	color:$TEXT_COLOR;
}

.tablesbrand {
	color:$ACCENT_COLOR;
}

.help-text {
	font-size:12px;
	color:$PARAGRAPH_GREY;
}

.link-text {
	font-size:$LINK_TEXT_SIZE;
	line-height:$LINK_LINE_HEIGHT;
	font-weight: $LINK_TEXT_WEIGHT;
}

.link-text:hover {
	cursor:pointer;
	color:$FADED_ACCENT_COLOR;
}

.normal-text {
	font-size: $LINK_TEXT_SIZE;
	color:$TEXT_COLOR;

}

.bold {
	font-weight: bold;
}

.italic {
	font-style:italic;
}

.app-button {
	color:white !important;
	background-color:$ACCENT_COLOR !important;
	border-radius:26.5px !important;
	transition: all 0.5s ease-out !important;
}

.app-button:hover {
	background-color:$FADED_ACCENT_COLOR !important;
	color:$ACCENT_COLOR !important;
}

.app-divider {
	height:20px;
	border-left:1px solid $INPUT_BORDER_COLOR;
}

.root {
	width:100%;
	height:100%;
}

.page {
	display:flex;
	flex-direction: column;
	min-height:100vh;

	.page-content {
		padding:2*$APP_SPACING_SIZE 0;
		flex: 1;
	
		.page-header {
			width:100%;
	
			.page-header-text {
				text-align: center;
				padding:3*$APP_SPACING_SIZE;
	
				.divider {
					width:100%;
					display:flex;
					justify-content: center;
				}
			}
		}
	}
}


.brand-divider {
	max-width:460px;
	
}

.center-content {
	width:100%;
	text-align: center;
}

.modal-small {
	max-width: 470px;
}

.modal-content {
	padding:40px;
}

.success-text {
	color:$ACCENT_COLOR !important;
}


.quill {
	.ql-editor {
		.ql-video {
			width: 100%;
			min-height: 400px;
		}
		img {
			max-height:1000px;
		}
	}

}