.display-resume {
    padding:4vw;
    background-color:white;
    border-radius: 5px;
    border:1px solid #f8f8f8;

    .resume-top {

        .user-image {
            .user-image-input {
                height:170px;
                
                display:flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                padding:30px;
                img {
                    height:50px;
                }
            }

            
            h2 {
                margin:0;
                text-align: center;
            }

            .user-img {
                img {
                    max-height: 170px;
                    max-width:100%;
                    margin:auto;
                }
            }
        }

        .name {
            display:flex;
            align-items: center;
            justify-content: flex-start;
            height: 100%;

            .name-content{
                display: flex;
                flex-direction: column;
                text-align: left;
            }
        }

        .details {
            display:flex;
            align-items: center;
            justify-content: flex-end;
            height: 100%;
            p {
                margin-bottom:5px;
            }
        }

    }

    .section {
        margin-top:60px;

        .resume-item {
            display:flex;
            justify-content: space-between;
        }

        .portfolio-item {
            margin-bottom:20px;
            // display:flex;
            .item-content {
                display:flex;
                
            }
        }
    }
}