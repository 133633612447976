.sidebar {

    display:flex;
    align-items: center;
    height:100%;

    .sidebar-icon {
        position: relative;
        z-index: 100;
        transition: all 0.4s ease-out;
        padding:10px;
    }

    .sidebar-underlay {
        position:fixed;
        z-index: 10;
        background-color:rgba(0,0,0,.3);
        height:100vh;
        width:100vw;
        top:0;
        left:0;
    }

    .sidebar-content {
        position:fixed;
        top:0;
        left:0;
        height: 100vh;
        width: 226px;
        z-index: 50;
        background-color:#fff;
        padding:100px calc(5vw + 1em) 20px calc(5vw + 1em);

        text-align: center;


        .sidebar-innercontent {

            display:flex;
            flex-direction: column;
            justify-content: space-between;
            position:relative;
            height: 100%;

            .sidebar-links {
                .sidebar-button {
                    box-shadow: 0 1px 2px 0 rgba(0,0,0,.05);
                    padding:5px;
                    display:flex;
                    justify-content: center;
                    margin:10px 0;
                }
            }

            .sidebar-bottom {
                position: relative;
                bottom:0;
                .help-text {
                    width: 100%;
                    display:inline-block;
                }
            }
        }
        

        

        
    }
}