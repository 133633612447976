.dropzone {
    .dropzone-inside {

        display:flex;
        align-items: center;
        justify-content: center;
        height:300px;
        
    }

    .dropzone-inside:hover {
        // border-color:rgb(70, 178, 158);
        // cursor:pointer;
    }

    .dropzone-loading {
        display:flex;
        height:300px;
        .dimmer {
            position: relative;
            .tables-animation {
                max-width: 300px;
            }
        }
    }

    .inside-content {
        flex: 1;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        border:1px dotted #ccc;
    }
    .drag-active {
        background-color:#f8f8f8;
        border-color:rgb(70, 178, 158);
    }

    .drop-finished {
        height:300px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border:1px dotted #ccc;
        .success {
            display:flex;
            p {
                margin:0;
                line-height: 2;
            }
            .checkmark {
                width:27px;
            }
        }
    }

    
}