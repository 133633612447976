.app-header {

    
    .ui.menu {
        .item {
            .right.item {
                border-left: 1px solid rgba(34,36,38,.1)
            }
        }

        .recruiter-logo {
            max-height: 44px;
            padding:0 1rem;
            height:100%;
            img {
                height:100%;
                width:auto;
            }
        }
    }

    .admin-navbar {
        background-color:#444444 !important;
        border-radius:0 !important;
        margin-bottom:0 !important;

        .grey-logo {
            img {
                max-height: 20px;
            }
        }

        .dashboard-item {
            a {
                color:#F2F3F5;
                img {
                    margin:0 8px;
                }
                display:flex;
            }
            a:hover {
                // color:#f8f9fa;
                color:#fff;
            }
            // display:flex;
        }

    }
}

.user-icon {
    svg {
        margin-right:10px;
    }
}