.rtf-editor {
    width:100%;

    .quill {

        .ql-toolbar {
            .ql-fill {
                // fill: #9b9b9b !important;
            }
            .ql-stroke {
                // fill: #9b9b9b !important;
            }
        }

        .ql-container {
            min-height:262px;

            .ql-video {
                width: 100%;
                height: 400px;
            }
        }
    }
    
    .ql-picker.ql-font {

        .ql-picker-label {
            &:before {
                content: attr(data-value) !important;
                font-size: 14px;
              }
        }

        .ql-picker-item {
          font-size: 0;
          &:before {
            content: attr(data-value) !important;
            font-size: 14px;
          }
        }
      }
}