.container-404 {
    display:flex;
    align-items:center;
    justify-content: center;
    width:100%;
    height:100vh;
    .content-404 {


        .img-container {
            width: 312px;
            margin:auto;
        }

        .header-1 {
            margin-top:40px;
        }
    }
    
}