/*
Semantic UI Notes:
Header sizes:
@h1 : unit((28 / 14), rem);
@h2 : unit((24 / 14), rem);
@h3 : unit((18 / 14), rem);
@h4 : unit((15 / 14), rem);
@h5 : unit((14 / 14), rem);
*/
.header-1 {
  font-size: 36px;
  color: #616262;
  line-height: 41px;
  margin-bottom: 10px; }

.header-2 {
  font-size: 26px;
  color: #616262; }

.tablesbrand {
  color: #46b29e; }

.help-text {
  font-size: 12px;
  color: #9b9b9b; }

.link-text {
  font-size: 14px;
  line-height: 21px;
  font-weight: bold; }

.link-text:hover {
  cursor: pointer;
  color: #dcedec; }

.normal-text {
  font-size: 14px;
  color: #616262; }

.bold {
  font-weight: bold; }

.italic {
  font-style: italic; }

.app-button {
  color: white !important;
  background-color: #46b29e !important;
  border-radius: 26.5px !important;
  transition: all 0.5s ease-out !important; }

.app-button:hover {
  background-color: #dcedec !important;
  color: #46b29e !important; }

.app-divider {
  height: 20px;
  border-left: 1px solid #dfe3e9; }

.root {
  width: 100%;
  height: 100%; }

.page {
  display: flex;
  flex-direction: column;
  min-height: 100vh; }
  .page .page-content {
    padding: 20px 0;
    flex: 1; }
    .page .page-content .page-header {
      width: 100%; }
      .page .page-content .page-header .page-header-text {
        text-align: center;
        padding: 30px; }
        .page .page-content .page-header .page-header-text .divider {
          width: 100%;
          display: flex;
          justify-content: center; }

.brand-divider {
  max-width: 460px; }

.center-content {
  width: 100%;
  text-align: center; }

.modal-small {
  max-width: 470px; }

.modal-content {
  padding: 40px; }

.success-text {
  color: #46b29e !important; }

.quill .ql-editor .ql-video {
  width: 100%;
  min-height: 400px; }

.quill .ql-editor img {
  max-height: 1000px; }

@media screen and (min-width: 768px) {
  .landing-page .hero-section .left-align {
    padding-right: 30%; }
  .landing-page .hero-section h1 {
    font-size: 36px; }
  .landing-page .logo-items {
    justify-content: space-evenly; }
  .landing-page .column-text {
    align-items: flex-start; }
  .landing-page .about-section .inner-content {
    height: 100%; }
  .landing-page .about-section .text-section-col {
    height: 100%; }
    .landing-page .about-section .text-section-col .text-section {
      height: 100%; } }

@media screen and (max-width: 767px) {
  .landing-page .column-text {
    align-items: center; }
  .landing-page .header {
    padding: 5vh 5vw !important; }
  .landing-page .hero-section {
    text-align: center;
    margin-bottom: 20px; }
  .landing-page .logo-items .logo {
    flex: 0 0 auto; }
  .landing-page #text-section-col {
    padding-left: 0 !important;
    padding-right: 0 !important;
    text-align: center; } }

.landing-page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding-bottom: 5vh; }
  .landing-page p, .landing-page div, .landing-page h1, .landing-page h2, .landing-page h3, .landing-page h4, .landing-page h5, .landing-page h6 {
    font-family: 'IBM Plex Sans', sans-serif !important;
    color: #616262; }
  .landing-page .link-text {
    font-size: 14px;
    line-height: 21px;
    font-weight: bold;
    color: #46b29e; }
  .landing-page .link-text:hover {
    cursor: pointer;
    color: #dcedec; }
  .landing-page .page-top {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column; }
  .landing-page .column-text {
    display: flex;
    flex: 1 1;
    height: 100%;
    align-items: center; }
  .landing-page .nav-button {
    color: #616262; }
  .landing-page .nav-button:hover {
    cursor: pointer; }
  .landing-page .section-padding {
    margin-top: 10vh; }
  .landing-page .header {
    padding-top: 5vh;
    z-index: 100; }
    .landing-page .header .tables-logo {
      max-width: 200px;
      display: flex;
      align-items: center; }
      .landing-page .header .tables-logo .beta-img {
        max-width: 30px;
        margin: 0 0 20px 5px; }
    .landing-page .header .nav-buttons {
      padding: 10px;
      display: flex;
      justify-content: space-evenly; }
  .landing-page .hero-section {
    flex: 1;
    display: flex;
    align-items: center; }
    .landing-page .hero-section .hero-image {
      max-width: 90%;
      margin: auto; }
      .landing-page .hero-section .hero-image img {
        margin: auto; }
  .landing-page .logo-section {
    background-color: #f9f9fb;
    display: flex;
    padding: 3vh 1vw;
    overflow-x: hidden; }
    .landing-page .logo-section .logo-items {
      display: flex;
      overflow-x: scroll;
      white-space: nowrap;
      align-items: center; }
      .landing-page .logo-section .logo-items::-webkit-scrollbar {
        display: none; }
      .landing-page .logo-section .logo-items .logo {
        display: inline-block;
        padding: 0 3vw; }
        .landing-page .logo-section .logo-items .logo img {
          max-height: 5vh;
          max-width: 180px; }
  .landing-page .about-section .app-statistic {
    position: relative; }
  .landing-page .about-section .ui.statistic {
    width: 100%; }
    .landing-page .about-section .ui.statistic .value {
      font-size: 36px !important;
      font-weight: bold !important;
      color: #46b29e; }
    .landing-page .about-section .ui.statistic .statistic-label {
      font-weight: bold;
      text-align: center;
      margin-top: 5px; }
  .landing-page .about-section .inner-content {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 370px; }
  .landing-page .about-section .text-section-col {
    background-color: #f9f9fb !important; }
    .landing-page .about-section .text-section-col .text-section-right {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      flex-grow: 1; }
    .landing-page .about-section .text-section-col .text-section {
      display: flex;
      align-items: center;
      padding: 60px; }
      .landing-page .about-section .text-section-col .text-section .wrap-text {
        max-width: 400px; }
  .landing-page .feature-section .features .feature {
    background-color: #fff; }
    .landing-page .feature-section .features .feature img {
      box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.05); }
    .landing-page .feature-section .features .feature .feature-content {
      padding: 20px;
      width: 100%;
      text-align: center; }
  .landing-page #signup-section {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .landing-page .signup-section .italic {
    margin-bottom: 0; }
  .landing-page .contact-section {
    margin: 60px 0; }
    .landing-page .contact-section p {
      text-align: center; }
    .landing-page .contact-section .contact-form-container {
      margin: auto;
      max-width: 781px; }
      .landing-page .contact-section .contact-form-container .submit-container {
        width: 100%;
        text-align: center; }
  .landing-page .footer-section .tables-logo {
    max-width: 200px;
    margin: auto; }
