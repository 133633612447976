.auth-modal {
    max-width: 470px;

    .header {
        padding:20px;
        text-align: center;
        
    }

    .content {
        padding:70px;

        .auth-buttons {
            display:flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            button {
                margin:10px 0;
            }
        }

        .github-login {
            background-color:#fff;
            box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
            border: none;
            padding: 0.5rem 2rem;
            border-radius: 5px;
        }
        .github-login:hover {
            cursor:pointer;
        }

        .linkedin-login {
            border: none;
            padding: 0.5rem 2rem;
            background-color: #006699;
            box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
            border-radius: 5px;
        }

        .linkedin-login:hover {
            cursor:pointer;
        }
    }

    .below-modal {
        position: relative;
        top: 30px;
        width: 100%;
        text-align: center;
        color: white;
        font-size:12px;
    }
}