.full-page-error {
    .modal-content {
        padding: 8vw;
        min-height:600px;
        display: flex;
        align-items: center;
        justify-content: center;

        .modal-content-inside {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;

            .center-content {
                margin-bottom:20px;
            }
        }
    }
}