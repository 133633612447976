.footer {
    display: flex;
    padding:20px;
    align-items: center;
    justify-content: center;
    background-color: #f2f3f5;
    .footer-text {
        display: flex;
        align-items: center;

        p {
            margin:10px;
            font-size:12px;
        }
    }
    img {
        max-width: 100px !important;
    }
}