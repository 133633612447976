/*
Semantic UI Notes:
Header sizes:
@h1 : unit((28 / 14), rem);
@h2 : unit((24 / 14), rem);
@h3 : unit((18 / 14), rem);
@h4 : unit((15 / 14), rem);
@h5 : unit((14 / 14), rem);
*/
.header-1 {
  font-size: 36px;
  color: #616262;
  line-height: 41px;
  margin-bottom: 10px; }

.header-2 {
  font-size: 26px;
  color: #616262; }

.tablesbrand {
  color: #46b29e; }

.help-text {
  font-size: 12px;
  color: #9b9b9b; }

.link-text {
  font-size: 14px;
  line-height: 21px;
  font-weight: bold; }

.link-text:hover {
  cursor: pointer;
  color: #dcedec; }

.normal-text {
  font-size: 14px;
  color: #616262; }

.bold {
  font-weight: bold; }

.italic {
  font-style: italic; }

.app-button {
  color: white !important;
  background-color: #46b29e !important;
  border-radius: 26.5px !important;
  transition: all 0.5s ease-out !important; }

.app-button:hover {
  background-color: #dcedec !important;
  color: #46b29e !important; }

.app-divider {
  height: 20px;
  border-left: 1px solid #dfe3e9; }

.root {
  width: 100%;
  height: 100%; }

.page {
  display: flex;
  flex-direction: column;
  min-height: 100vh; }
  .page .page-content {
    padding: 20px 0;
    flex: 1; }
    .page .page-content .page-header {
      width: 100%; }
      .page .page-content .page-header .page-header-text {
        text-align: center;
        padding: 30px; }
        .page .page-content .page-header .page-header-text .divider {
          width: 100%;
          display: flex;
          justify-content: center; }

.brand-divider {
  max-width: 460px; }

.center-content {
  width: 100%;
  text-align: center; }

.modal-small {
  max-width: 470px; }

.modal-content {
  padding: 40px; }

.success-text {
  color: #46b29e !important; }

.quill .ql-editor .ql-video {
  width: 100%;
  min-height: 400px; }

.quill .ql-editor img {
  max-height: 1000px; }

@media screen and (max-width: 991px) {
  .position {
    text-align: center; }
    .position .position-end {
      align-items: center !important; }
      .position .position-end button {
        width: 100%; } }

.position .card {
  margin: 20px 0 !important; }
  .position .card .position-content {
    padding: 4vh; }
    .position .card .position-content .position-start p {
      color: #616262; }
    .position .card .position-content .position-end {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-end; }

.position .position-title {
  padding-top: 10px; }

.position .position-desc {
  padding: 10px 0; }
