.view-tables {
    
    .page-header {

        

        .seats-toggle {
            display:flex;
            align-items: flex-start;
            .checkbox {
                // float:left;
            }
            .toggle-text {
                margin-left:20px;
            }
        }
    }
}

.no-tables-modal {

    .header-content {
        display:flex;
        justify-content: space-between;
        align-items: center;
    }

    .no-tables-content {
        padding:30px;

        .success-content {
            display: flex;
            justify-content: space-between;
            align-items: center;
            p {
                margin:0;
            }
        }
        .success-animation {
            max-width: 40px;
        }
    }
}